var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.handleCategories.length !== 0
    ? _c(
        "div",
        { staticClass: "skillsBox" },
        _vm._l(_vm.handleCategories, function(item, index) {
          return _c("div", { key: index, staticClass: "skillItem" }, [
            _vm._m(0, true),
            _c("div", { staticClass: "skillContent" }, [
              _vm._v(_vm._s(item.text))
            ])
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "skillIcon" }, [
      _c("i", { staticClass: "iconfont_Me icon_tag2 iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }