<template>
  <div class="avatarBox">
    <div class="avatar">
      <van-image
        width="100%"
        height="100%"
        fit="cover"
        :src="formartImageUrl(src)"
      >
        <template v-slot:error>
          <i class="iconfont_Me icon_user-fill" :style="{fontSize:'20px'}"></i>
        </template>
      </van-image>
    </div>
    <div class="nationalFlag" v-if="country && country!=='' && country!=='null'" :style="{width:`${nationalFlagSize}px`,height:`${nationalFlagSize}px`}">
      <van-image
        width="100%"
        height="100%"
        fit="cover"
        :src="require(`../../../../../assets/images/newCountrys/country_flag_${country}.png`)"
      >
        <template v-slot:error></template>
      </van-image>
    </div>
  </div>
</template>
<script>
import { formartImageUrl } from "@/utils/help";
export default {
  props: {
    // 头像地址
    src: {
      type: String,
      default: ""
    },
    // 国旗编码
    country: {
      type: String,
      default: ""
    },
    // 国旗尺寸
    nationalFlagSize: {
      type:Number,
      default: 24
    }
  },
  methods: {
    formartImageUrl,
  }
}
</script>
<style lang="stylus" scoped>
  .avatarBox
    width 100%;
    height 100%;
    position relative;
    .avatar
      width 100%;
      height 100%;
      border-radius 50%;
      overflow hidden;
      .iconStyle
        font-size 20px;
    .nationalFlag
      background transparent;
      border-radius 50%;
      overflow hidden;
      position absolute;
      bottom 0;
      right 0;
      z-index 2;

</style>