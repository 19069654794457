var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "multiplePictures", staticClass: "multiplePictures" },
    [
      _c(
        "div",
        {
          staticClass: "pictruesContainer",
          style: {
            width: _vm.containerMaxWidth + "px",
            height: _vm.calculatedHeight + "px"
          }
        },
        _vm._l(_vm.handlePictures, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "pictrueItem",
              style: {
                width: _vm.sideLength + "px",
                height: _vm.sideLength + "px",
                top:
                  ((_vm.columns == 2 && index < 2) ||
                  (_vm.columns == 3 && index < 3)
                    ? 0
                    : index < 6
                    ? _vm.coordinateData
                    : _vm.coordinateData * 2) + "px",
                left: (index % _vm.columns) * _vm.coordinateData + "px"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.selectPictrue(index)
                }
              }
            },
            [
              _vm.remainingUnshown != 0 && index == 8
                ? _c("div", { staticClass: "lastMask" }, [
                    _vm._v(
                      "\n        +" + _vm._s(_vm.remainingUnshown) + "\n      "
                    )
                  ])
                : _vm._e(),
              _c("Picture", {
                attrs: { src: item },
                on: { imageLoadSuccess: _vm.imageLoadSuccess }
              })
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }