var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "postItem",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.jumpPostInfo($event)
        }
      }
    },
    [
      _c("PostHeader", { attrs: { postInfo: _vm.postInfo } }),
      _c("PostContent", { attrs: { postInfo: _vm.postInfo } }),
      _c("PostAttachments", { attrs: { postInfo: _vm.postInfo } }),
      _c("PostCategories", {
        attrs: { postInfo: _vm.postInfo, categories: _vm.categories }
      }),
      _c("PostFooter", { attrs: { postInfo: _vm.postInfo } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }