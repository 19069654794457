<template>
  <div class="postContentBox">
    <div class="textComputedBox" ref="textComputedBox">
      {{ handleTextDetail }}
    </div>
    <div class="textItemcomputedBox" ref="textItemcomputedBox">
      {{ textItemDetail }}
    </div>
    <div class="moreThanFivelinesBox" v-if="moreThanFivelinesOfText !== ''">
      {{ moreThanFivelinesOfText }}
    </div>
    <div class="processedDataBox" v-if="processedData.length !== 0">
      <DisplayComponent
        v-for="(item, index) in processedData"
        :key="index"
        :item="item"
      ></DisplayComponent>
    </div>
  </div>
</template>
<script>
import DisplayComponent from "../displayComponent";
export default {
  components: {
    DisplayComponent,
  },
  props: {
    // 帖子详情
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // 超过五行的文本，直接展示到对应的盒子里面
      moreThanFivelinesOfText: "",
      // 用于技术文本行数的变量
      textItemDetail: "",
      // 没有超过五行，则按照帖子的数据格式显示
      processedData: [],
    };
  },
  computed: {
    // 处理过的文本数据
    handleTextDetail() {
      let handleTextDetail = "";
      if (this.postInfo && this.postInfo.detail !== "") {
        try {
          let detailArr = JSON.parse(this.postInfo.detail);
          detailArr.forEach((element, index) => {
            let isText = element.key === "text" && element.content !== "";
            if (isText && index !== detailArr.length - 1) {
              handleTextDetail = handleTextDetail + element.content + "\n";
            } else if (isText && index === detailArr.length - 1) {
              handleTextDetail = handleTextDetail + element.content;
            }
          });
        } catch (e) {
          handleTextDetail = this.postInfo.detail
            .replace(/<.*?>/g, "")
            .replace(/&lt;.*?&gt;/g, "");
        }
      }
      return handleTextDetail;
    },
    // 已处理的数据格式
    handlerDetailData() {
      let handlerDetailData = [];
      if (this.postInfo && this.postInfo.detail !== "") {
        try {
          handlerDetailData = JSON.parse(this.postInfo.detail);
        } catch (e) {
          handlerDetailData = [
            {
              content: this.postInfo.detail
                .replace(/<.*?>/g, "")
                .replace(/&lt;.*?&gt;/g, ""),
              key: "text",
            },
          ];
        }
      }
      return handlerDetailData;
    },
    // 是否有附件，如果没有附件，就可以显示帖子详情里的图片或视频
    withAttachments() {
      let withAttachments = false;
      if (this.postInfo.attachments.length > 0) {
        withAttachments = true;
      }
      return withAttachments;
    },
  },
  watch: {
    // 处理后的文本内容，用于获取文本高度
    handleTextDetail: {
      handler(val, old) {
        if (val !== old) {
          this.$nextTick(() => {
            this.processedData = [];
            this.moreThanFivelinesOfText = "";
            // 文本没有到五行
            if (this.$refs.textComputedBox.offsetHeight < 130) {
              this.handlerRowsDetail();
            } else {
              this.handlerTextDetail(val);
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 文本行数不到五行，则显示除文本外其他数据，凑齐五行
    async handlerRowsDetail() {
      // 最多五行
      let limitLines = 5;
      let processedData = [];
      if (this.handlerDetailData.length > 0) {
        for (let i = 0; i < this.handlerDetailData.length; i++) {
          let item = this.handlerDetailData[i];
          // 首先判断单个文本数据的行数，防止一个文本数据就占了一行以上
          if (item.key === "text" && limitLines > 0) {
            let lines = await this.computedTextItemlines(item.content);
            // 计算一下还有多少行可以装填
            limitLines = limitLines - lines;
            processedData.push(item);
          }
          // 如果是超链接，并且它的下一条就是超链接预览的话，那这个两个算一行
          if (
            item.key === "url" &&
            this.handlerDetailData[i + 1] &&
            this.handlerDetailData[i + 1].key === "web" &&
            limitLines > 0
          ) {
            // 计算一下还有多少行可以装填
            limitLines = limitLines - 1;
            try {
              item.content = JSON.parse(item.content);
            } catch (e) {}
            processedData.push(item);
            processedData.push(this.handlerDetailData[i + 1]);
            // 如果是超链接，但是它的下一条不是超链接预览的话，那这条超链接单独算一行
          } else if (
            item.key === "url" &&
            this.handlerDetailData[i + 1] &&
            this.handlerDetailData[i + 1].key !== "web" &&
            limitLines > 0
          ) {
            // 计算一下还有多少行可以装填
            limitLines = limitLines - 1;
            try {
              item.content = JSON.parse(item.content);
            } catch (e) {}
            processedData.push(item);
            // 如果是预览，但它的上一条不是超链接的话，这条预览单独算一行
          } else if (
            item.key === "web" &&
            this.handlerDetailData[i - 1] &&
            this.handlerDetailData[i - 1].key !== "url" &&
            limitLines > 0
          ) {
            // 计算一下还有多少行可以装填
            limitLines = limitLines - 1;
            processedData.push(item);
            // 如果是预览，它是第一胎的话，这条预览单独算一行
          } else if (item.key === "web" && i === 0 && limitLines > 0) {
            // 计算一下还有多少行可以装填
            limitLines = limitLines - 1;
            processedData.push(item);
          }
        }
        // 如果在上面处理过后，数据为空，则看看是否有附件，没有附件的话，就展示一个帖子内容里的图片或者视频
        if (processedData.length === 0 && !this.withAttachments) {
          for (let i = 0; i < this.handlerDetailData.length; i++) {
            if (
              this.handlerDetailData[i].key === "image" ||
              this.handlerDetailData[i].key === "video"
            ) {
              processedData.push(this.handlerDetailData[i]);
              break;
            }
          }
        }
        this.processedData = processedData;
      }
    },
    // 计算单个text文本所占的行数
    computedTextItemlines(text) {
      return new Promise((resolve) => {
        this.textItemDetail = text;
        this.$nextTick(() => {
          let lines = 0;
          lines = this.$refs.textItemcomputedBox.offsetHeight / 26;
          resolve(lines);
        });
      });
    },
    // 由于文本行数超过五行，就只显示五行文本
    handlerTextDetail(text) {
      this.moreThanFivelinesOfText = text.replace(/&nbsp;/gi, " ");
    },
  },
};
</script>
<style lang="stylus" scoped>
.postContentBox
  width 100%;
  min-height 0;
  overflow hidden;
  margin 10px 0;
  position relative;
  .processedDataBox
    overflow hidden;
    width 100%;
  .moreThanFivelinesBox
    font-size 16px;
    line-height 26px;
    color #333333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    position relative;
    z-index 2;
  .textComputedBox,.textItemcomputedBox
    opacity 0;
    color #333333;
    font-size 16px;
    line-height 26px;
    top 0;
    left 0;
    position absolute;
    word-wrap: break-word;
    word-break: normal;
    z-index 1;
</style>
