<template>
  <div class="skillsBox" v-if="handleCategories.length !== 0">
    <div class="skillItem" v-for="(item,index) in handleCategories" :key="index">
      <div class="skillIcon">
        <i class="iconfont_Me icon_tag2 iconStyle"></i>
      </div>
      <div class="skillContent">{{item.text}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 帖子详情
    postInfo: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    // 帖子类型
    categories: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    // 处理过的帖子类型
    handleCategories() {
      let categorys = [];
      if(this.postInfo.categoryId !== '') {
        let categoryIds = this.postInfo.categoryId.split(',');
        categorys = this.categories.filter( e => {
          return categoryIds.some(item => item === e.value);
        })
      }
      return categorys;
    }
  }
}
</script>
<style lang="stylus" scoped>
  .skillsBox
    width 100%;
    overflow hidden;
    margin-top 20px;
    .skillItem
      height 26px;
      min-width 64px;
      margin 0 6px 10px 0;
      border-radius 13px;
      background #F2FCF8;
      box-sizing border-box;
      display inline-flex;
      align-items center;
      justify-content flex-start;
      .skillIcon
        margin-left 3px;
        flex-shrink 0;
        .iconStyle
          font-size 20px;
          color #33CC66
      .skillContent
        flex 1;
        text-align center;
        font-size 12px;
        color #1D4329;
        box-sizing border-box;
        padding 0 10px;

</style>