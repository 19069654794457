<template>
  <div class="postAttachments" v-if="postInfo.attachments.length !== 0">
    <div
      class="videoAttachments"
      v-if="postInfo.documentType === 1 && videoConfig.path !== ''"
    >
      <video
        class="videoStyle"
        controls="controls"
        type="video/mp4"
        webkit-playsinline="true"
        playsinline="true"
        x-webkit-airplay="allow"
        x5-video-player-type="h5"
        x5-video-orientation="portraint"
        x5-playsinline="true"
        x5-video-player-fullscreen="true"
        :src="videoConfig.path"
      ></video>
    </div>
    <PicturePreview
      v-if="postInfo.documentType === 3 && pictures.length !== 0"
      :pictures="pictures"
      @imageLoadSuccess="imageLoadSuccess"
    ></PicturePreview>
    <FileAttachments
      v-if="postInfo.documentType === 4 && fileInfo.path !== ''"
      :fileInfo="fileInfo"
    ></FileAttachments>
  </div>
</template>
<script>
import PicturePreview from "../picturePreview/basis";
import FileAttachments from "../fileItem";
export default {
  components: {
    PicturePreview,
    FileAttachments,
  },
  props: {
    // 帖子详情
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    // documentType: 0为文字帖子；1为视频帖子；2为音频帖子；3为图片帖子；4为附件帖子
    // 帖子视频附件
    videoConfig() {
      let videoConfig = new Object();
      // 为了兼容老帖子，需要遍历将附件类型中的视频文件找出来(老数据可能会有除了视频以外的其他数据，所以必须过滤一次)
      if (
        this.postInfo.documentType === 1 &&
        this.postInfo.attachments.length !== 0
      ) {
        let videoItems = this.postInfo.attachments.filter((item) => {
          return this.matchFileSuffixType(item.path) === "video";
        });
        if (videoItems && videoItems.length !== 0) {
          // 默认只取第一个视频
          videoConfig.path = videoItems[0].path;
        } else {
          console.error(
            "视频帖子数据错误，未检测到有视频文件，请检查后台数据是否有误",
            this.gigId
          );
        }
      } else {
        console.error(
          "视频帖子数据错误，文件列表为空，请检查后台数据是否有误",
          this.gigId
        );
      }
      return videoConfig;
    },
    // 帖子图片附件
    pictures() {
      let pictures = [];
      // 为了兼容老帖子，需要遍历将附件类型中的图片文件找出来
      if (
        this.postInfo.documentType === 3 &&
        this.postInfo.attachments.length !== 0
      ) {
        let imageItems = this.postInfo.attachments.filter((item) => {
          return this.matchFileSuffixType(item.path) === "image";
        });
        if (imageItems && imageItems.length !== 0) {
          imageItems.forEach((e) => {
            pictures.push(e.path);
          });
        } else {
          console.error(
            "图片帖子数据错误，未检测到有图片文件，请检查后台数据是否有误",
            this.postInfo.gigId
          );
        }
      } else {
        console.error(
          "图片帖子数据错误，文件列表为空，请检查后台数据是否有误",
          this.postInfo.gigId
        );
      }
      return pictures;
    },
    // 帖子文件附件
    fileInfo() {
      let fileInfo = {
        name: "",
        path: "",
      };
      if (
        this.postInfo.documentType === 4 &&
        this.postInfo.attachments.length !== 0
      ) {
        let fileItems = this.postInfo.attachments.filter((item) => {
          return (
            this.matchFileSuffixType(item.path) !== "video" &&
            this.matchFileSuffixType(item.path) !== "image"
          );
        });
        if (fileItems && fileItems.length !== 0) {
          fileInfo.name = fileItems[0].name;
          fileInfo.path = fileItems[0].path;
        } else {
          console.error(
            "文件帖子数据错误，未检测到有非视频或非图片的文件，请检查后台数据是否有误",
            this.gigId
          );
        }
      } else {
        console.error(
          "文件帖子数据错误，文件列表为空，请检查后台数据是否有误",
          this.gigId
        );
      }
      return fileInfo;
    },
  },
  methods: {
    // 图片加载成功
    imageLoadSuccess() {
      this.$forceUpdate();
    },
    // 判断文件类型
    matchFileSuffixType(fileName) {
      // 后缀获取
      let suffix = "";
      // 获取类型结果
      let result = "";
      try {
        let flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      let imglist = [
        "bmp",
        "jpg",
        "jpeg",
        "png",
        "tif",
        "gif",
        "pcx",
        "tga",
        "exif",
        "fpx",
        "svg",
        "psd",
        "cdr",
        "pcd",
        "dxf",
        "ufo",
        "eps",
        "ai",
        "raw",
        "WMF",
        "webp",
        "avif",
        "jfif",
      ];
      // 进行图片匹配
      result = imglist.some((item) => {
        return item == suffix.toLowerCase();
      });
      if (result) {
        result = "image";
        return result;
      }
      // 匹配 视频
      let videolist = ["asf", "asx", "rm", "mp4", "m4v", "dat", "mkv", "vob"];
      result = videolist.some((item) => {
        return item == suffix.toLowerCase();
      });
      if (result) {
        result = "video";
        return result;
      }
      // 匹配 音频
      let radiolist = ["mp3", "wav"];
      result = radiolist.some((item) => {
        return item == suffix.toLowerCase();
      });
      if (result) {
        result = "radio";
        return result;
      }
      // 其他 文件类型
      result = "other";
      return result;
    },
  },
};
</script>
<style lang="stylus" scoped>
.postAttachments
  width 100%;
  overflow hidden;
  margin 0;
  .videoAttachments
    height 214px;
    width 100%;
    border-radius 6px;
    overflow hidden;
    video
      height 100%;
      width 100%;
      background #999999;
      border-radius 6px;
</style>
