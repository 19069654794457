<template>
  <div class="previewBox" ref="previewBox">
    <SinglePicture v-if="isSinglePicture" :pictures="pictures" @imageLoadSuccess="imageLoadSuccess" @selectPictrue="selectPictrue"></SinglePicture>
    <MultiplePictures v-else :pictures="pictures" @imageLoadSuccess="imageLoadSuccess" @selectPictrue="selectPictrue"></MultiplePictures>
  </div>
</template>
<script>
// 单张预览
import SinglePicture from "./singlePicture";
// 多张预览
import MultiplePictures from "./multiplePictures";
// 图片预览
import { ImagePreview } from 'vant';
export default {
  components: {
    SinglePicture,
    MultiplePictures,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  props: {
    // 传入的图片集合
    pictures: {
      type: Array,
      default:() => {
        return [];
      }
    },
  },
  watch: {
    // 监听图片集合是否改变
    pictures: {
      handler(val) {
        // 如果只有一张的图片的话，则要需要计算这图片的比例
        if(val.length == 1) {
          this.isSinglePicture = true;
        } else {
          this.isSinglePicture = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      // 是否是单张图片
      isSinglePicture: false,
    }
  },
  mounted() {},
  methods: {
    // 点击选择这张图片
    selectPictrue(index) {
      ImagePreview({
        images: this.pictures,
        startPosition: index,
      });
    },
    // 图片加载成功
    imageLoadSuccess(src) {
      this.$emit('imageLoadSuccess',src);
    }
  }
}
</script>
<style lang="stylus" scoped>
  .previewBox
    width 100%;
    overflow hidden;

</style>