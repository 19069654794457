var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "avatarBox" }, [
    _c(
      "div",
      { staticClass: "avatar" },
      [
        _c("van-image", {
          attrs: {
            width: "100%",
            height: "100%",
            fit: "cover",
            src: _vm.formartImageUrl(_vm.src)
          },
          scopedSlots: _vm._u([
            {
              key: "error",
              fn: function() {
                return [
                  _c("i", {
                    staticClass: "iconfont_Me icon_user-fill",
                    style: { fontSize: "20px" }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    ),
    _vm.country && _vm.country !== "" && _vm.country !== "null"
      ? _c(
          "div",
          {
            staticClass: "nationalFlag",
            style: {
              width: _vm.nationalFlagSize + "px",
              height: _vm.nationalFlagSize + "px"
            }
          },
          [
            _c("van-image", {
              attrs: {
                width: "100%",
                height: "100%",
                fit: "cover",
                src: require("../../../../../assets/images/newCountrys/country_flag_" +
                  _vm.country +
                  ".png")
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "error",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1652857265
              )
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }