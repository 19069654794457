var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "postFooterBox",
      style: { marginTop: _vm.postInfo.categoryId !== "" ? "0px" : "10px" }
    },
    [
      _c("div", { staticClass: "leftBox" }, [
        _vm._m(0),
        _c("div", { staticClass: "leftText" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.numberForShow(_vm.postInfo.pageViewCount)) +
              "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "rightBox" }, [
        _c(
          "div",
          {
            staticClass: "commonBox",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.changeLikeStatus($event)
              }
            }
          },
          [
            _c("div", { staticClass: "iconBox" }, [
              _c("i", {
                staticClass: "iconfont_Me iconStyle",
                class: _vm.postInfo.likeStatus
                  ? "icon_fabulous-fill"
                  : "icon_fabulous",
                style: { color: _vm.postInfo.likeStatus ? "#EF4B4B" : "" }
              })
            ]),
            _c("div", { staticClass: "commonText" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.numberForShow(_vm.postInfo.likeCount)) +
                  "\n      "
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "commonBox",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.jumpPostInfo($event)
              }
            }
          },
          [
            _vm._m(1),
            _c("div", { staticClass: "commonText" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.numberForShow(_vm.postInfo.commentCount)) +
                  "\n      "
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconBox" }, [
      _c("i", { staticClass: "iconfont_Me icon_Views iconStyle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconBox" }, [
      _c("i", { staticClass: "iconfont_Me icon_information iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }