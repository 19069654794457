import { render, staticRenderFns } from "./singlePicture.vue?vue&type=template&id=6aa45748&scoped=true&"
import script from "./singlePicture.vue?vue&type=script&lang=js&"
export * from "./singlePicture.vue?vue&type=script&lang=js&"
import style0 from "./singlePicture.vue?vue&type=style&index=0&id=6aa45748&lang=stylus&scoped=true&"
import style1 from "./singlePicture.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa45748",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6aa45748')) {
      api.createRecord('6aa45748', component.options)
    } else {
      api.reload('6aa45748', component.options)
    }
    module.hot.accept("./singlePicture.vue?vue&type=template&id=6aa45748&scoped=true&", function () {
      api.rerender('6aa45748', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/business/newVersion/common/picturePreview/basis/singlePicture.vue"
export default component.exports