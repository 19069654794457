var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "postHeaderBox" }, [
    _c(
      "div",
      { staticClass: "userImageBox" },
      [
        _c("AvatarBox", {
          attrs: {
            src: _vm.postInfo.profilePhoto,
            country: _vm.postInfo.country.toString(),
            nationalFlagSize: 16
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "userInfoBox" }, [
      _c("div", { staticClass: "userName" }, [
        _vm._v("\n      " + _vm._s(_vm.postInfo.name) + "\n    ")
      ]),
      _c("div", { staticClass: "publishTime" }, [
        _vm._v("\n      " + _vm._s(_vm.postInfo.publishTime) + "\n    ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }