<template>
  <div class="postItem" @click.stop="jumpPostInfo">
    <PostHeader :postInfo="postInfo"></PostHeader>
    <PostContent :postInfo="postInfo"></PostContent>
    <PostAttachments :postInfo="postInfo"></PostAttachments>
    <PostCategories :postInfo="postInfo" :categories="categories"></PostCategories>
    <PostFooter :postInfo="postInfo"></PostFooter>
  </div>
</template>
<script>
import PostHeader from "./postRelated/postHeader";
import PostContent from "./postRelated/postContent";
import PostFooter from "./postRelated/postFooter";
import PostAttachments from "./postRelated/postAttachments";
import PostCategories from "./postRelated/postCategories";
export default {
  components: {
    PostHeader,
    PostContent,
    PostFooter,
    PostCategories,
    PostAttachments,
  },
  props: {
    // 帖子详情
    postInfo: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    // 帖子类型
    categories: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    // 跳转到帖子详情页面
    jumpPostInfo() {
      this.$router.push({
        name: 'detail',
        params: { id: "pai_" + Base64.encode(this.postInfo.gigId), type: 'service' }
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
  .postItem
    width 100%;    
    background #FFFFFF;
    padding 10px 0 20px 0;

</style>