<template>
  <transition name="fade">
    <van-image
      :src="pictureSrc"
      v-show="imageReady"
      @load="imageLoadSuccess"
      @error="imageLoadError"
      width="100%"
      height="100%"
      fit="cover"
    >
      <template v-slot:error>Melinked</template>
    </van-image>
  </transition>
</template>
<script>
import { formartImageUrl } from "@/utils/help";
export default {
  props: {
    src: {
      type:String,
      default: ''
    },
    className: {
      type:String,
      default: ''
    }
  },
  data() {
    return {
      imageReady: false
    }
  },
  computed: {
    // 图片地址
    pictureSrc() {
      return this.formartImageUrl(this.src) ? this.formartImageUrl(this.src) : this.src;
    }
  },
  methods:{
    formartImageUrl,
    // 图片加载成功
    imageLoadSuccess() {
      this.imageReady = true;
      this.$emit('imageLoadSuccess',this.src)
    },
    // 图片加载失败
    imageLoadError() {
      this.imageReady = true;
      // 为了方便计算，失败也要调用成功，以便重新布局
      this.$emit('imageLoadSuccess',this.src)
    }
  }
}
</script>
<style lang="stylus" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

</style>