var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.postInfo.attachments.length !== 0
    ? _c(
        "div",
        { staticClass: "postAttachments" },
        [
          _vm.postInfo.documentType === 1 && _vm.videoConfig.path !== ""
            ? _c("div", { staticClass: "videoAttachments" }, [
                _c("video", {
                  staticClass: "videoStyle",
                  attrs: {
                    controls: "controls",
                    type: "video/mp4",
                    "webkit-playsinline": "true",
                    playsinline: "true",
                    "x-webkit-airplay": "allow",
                    "x5-video-player-type": "h5",
                    "x5-video-orientation": "portraint",
                    "x5-playsinline": "true",
                    "x5-video-player-fullscreen": "true",
                    src: _vm.videoConfig.path
                  }
                })
              ])
            : _vm._e(),
          _vm.postInfo.documentType === 3 && _vm.pictures.length !== 0
            ? _c("PicturePreview", {
                attrs: { pictures: _vm.pictures },
                on: { imageLoadSuccess: _vm.imageLoadSuccess }
              })
            : _vm._e(),
          _vm.postInfo.documentType === 4 && _vm.fileInfo.path !== ""
            ? _c("FileAttachments", { attrs: { fileInfo: _vm.fileInfo } })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }