var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "previewBox", staticClass: "previewBox" },
    [
      _vm.isSinglePicture
        ? _c("SinglePicture", {
            attrs: { pictures: _vm.pictures },
            on: {
              imageLoadSuccess: _vm.imageLoadSuccess,
              selectPictrue: _vm.selectPictrue
            }
          })
        : _c("MultiplePictures", {
            attrs: { pictures: _vm.pictures },
            on: {
              imageLoadSuccess: _vm.imageLoadSuccess,
              selectPictrue: _vm.selectPictrue
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }