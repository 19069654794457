var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "postContentBox" }, [
    _c("div", { ref: "textComputedBox", staticClass: "textComputedBox" }, [
      _vm._v("\n    " + _vm._s(_vm.handleTextDetail) + "\n  ")
    ]),
    _c(
      "div",
      { ref: "textItemcomputedBox", staticClass: "textItemcomputedBox" },
      [_vm._v("\n    " + _vm._s(_vm.textItemDetail) + "\n  ")]
    ),
    _vm.moreThanFivelinesOfText !== ""
      ? _c("div", { staticClass: "moreThanFivelinesBox" }, [
          _vm._v("\n    " + _vm._s(_vm.moreThanFivelinesOfText) + "\n  ")
        ])
      : _vm._e(),
    _vm.processedData.length !== 0
      ? _c(
          "div",
          { staticClass: "processedDataBox" },
          _vm._l(_vm.processedData, function(item, index) {
            return _c("DisplayComponent", { key: index, attrs: { item: item } })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }