var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _c("van-image", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.imageReady,
            expression: "imageReady"
          }
        ],
        attrs: {
          src: _vm.pictureSrc,
          width: "100%",
          height: "100%",
          fit: "cover"
        },
        on: { load: _vm.imageLoadSuccess, error: _vm.imageLoadError },
        scopedSlots: _vm._u([
          {
            key: "error",
            fn: function() {
              return [_vm._v("Melinked")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }