<template>
  <div class="postFooterBox" :style="{marginTop:postInfo.categoryId!==''?'0px':'10px'}">
    <div class="leftBox">
      <div class="iconBox">
        <i class="iconfont_Me icon_Views iconStyle"></i>
      </div>
      <div class="leftText">
        {{numberForShow(postInfo.pageViewCount)}}
      </div>
    </div>
    <div class="rightBox">
      <div class="commonBox" @click.stop="changeLikeStatus">
        <div class="iconBox">
          <i class="iconfont_Me iconStyle" :class="postInfo.likeStatus?'icon_fabulous-fill':'icon_fabulous'" :style="{color:postInfo.likeStatus?'#EF4B4B':''}"></i>
        </div>
        <div class="commonText">
          {{numberForShow(postInfo.likeCount)}}
        </div>
      </div>
      <div class="commonBox" @click.stop="jumpPostInfo">
        <div class="iconBox">
          <i class="iconfont_Me icon_information iconStyle"></i>
        </div>
        <div class="commonText">
          {{numberForShow(postInfo.commentCount)}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { changelikeStatus } from "@/api/webApi";
export default {
  props: {
    // 帖子详情
    postInfo: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  methods: {
    // 处理展示数据（小于99展示真实数据，大于99小于1000，显示99+；大于1000小于10000显示1K+；大于10000显示1W+）
    numberForShow(number) {
      let handlerNumber = Number(number);
      let showText = "";
      if(handlerNumber <= 99) {
        showText = handlerNumber.toString();
      } else if(handlerNumber > 99 && handlerNumber <= 1000) {
        showText = "99+";
      } else if(handlerNumber > 1000 && handlerNumber <= 10000) {
        showText = "1K+";
      } else if(handlerNumber > 10000) {
        showText = "1W+";
      }
      return showText;
    },
    // 改版这个帖子的喜欢状态
    async changeLikeStatus() {
      let loginUser = this.$store.state.User.userinfo;
      if (loginUser && Object.keys(loginUser).length && loginUser.id) {
        let params = {
          userId: loginUser.id,
          language: localStorage.getItem("langCode") || "1",
          action: !this.postInfo.likeStatus,
          type: 1
        };
        let result = await changelikeStatus(this.postInfo.gigId,params);
        if(result.code === 201) {
         this.postInfo.likeStatus = !this.postInfo.likeStatus;
         let likeCount = Number(this.postInfo.likeCount);
         if(this.postInfo.likeStatus) {
           this.postInfo.likeCount = (likeCount + 1).toString();
         }else{
           this.postInfo.likeCount = (likeCount - 1).toString();
         }
        } else {
          console.log(result.message)
        }
      } else {
        this.$router.push({
          name: "login",
          query: {
            redirect: this.$route.name,
            ...this.$route.params
          }
        });
      }
    },
    // 跳转到帖子详情页面
    jumpPostInfo() {
      this.$router.push({
        name: 'detail',
        params: { id: "pai_" + Base64.encode(this.postInfo.gigId), type: 'service' }
      })
    }
  }
  
}
</script>
<style lang="stylus" scoped>
  .postFooterBox
    width 100%;
    height 28px;
    overflow hidden;
    display flex;
    align-items center;
    justify-content flex-start;
    .iconBox
      width 28px;
      height 28px;
      margin-right 2px;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 26px;
        color #200E32;
    .leftBox
      height 100%;
      min-width 28px;
      flex-shrink 0;
      overflow hidden;
      display flex;
      align-items center;
      justify-content flex-start;
      .leftText
        font-size 13px;
        line-height 18px;
        color #1A051D
    .rightBox
      flex 1;
      min-width 0;
      height 100%;
      text-align right;
      .commonBox
        height 100%;
        overflow hidden;
        display inline-flex;
        margin-right 18px;
        align-items center;
        justify-content flex-start;
        &:last-child
          margin-right 0;
        .commonText
          font-size 12px;
          color #333333;

</style>