<template>
  <div class="postHeaderBox">
    <div class="userImageBox">
      <AvatarBox
        :src="postInfo.profilePhoto"
        :country="postInfo.country.toString()"
        :nationalFlagSize="16"
      ></AvatarBox>
    </div>
    <div class="userInfoBox">
      <div class="userName">
        {{postInfo.name}}
      </div>
      <div class="publishTime">
        {{postInfo.publishTime}}
      </div>
    </div>
  </div>
</template>
<script>
import AvatarBox from "../avatarBox"
export default {
  components: {
    AvatarBox
  },
  props: {
    // 帖子详情
    postInfo: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
}
</script>
<style lang="stylus" scoped>
  .postHeaderBox
    width 100%;
    height 44px;
    overflow hidden;
    display flex;
    align-items center;
    justify-content flex-start;
    .userImageBox
      width 44px;
      height 44px;
      flex-shrink 0;
      overflow hidden;
    .userInfoBox
      flex 1;
      min-width 0;
      height 44px;
      overflow hidden;
      box-sizing border-box;
      padding-left 7px;
      display flex;
      flex-flow column;
      align-items flex-start;
      justify-content center;
      .userName
        font-size 16px;
        font-weight bold;
        color #1A051D;
        text-align left;
        line-height 26px;
      .publishTime
        font-size 11px;
        color #A8A8A8;
        text-align left;
        line-height 13px;
      

</style>